@use "sass:math";
@use 'variables' as v;
@use 'mixins' as m;

.wrapper {
    width: 90%;
    margin: 0 auto;
    height: 100%;
    @include m.tablet{
        width: v.$main_width;
    }
}

.mTop-3{
    margin-top: v.$medium--gap;
}

.mTop-5{
    margin-top: v.$gap;
}

.mTop-10{
    margin-top: v.$big--gap;
}

.w-100{
    width: 100%;
}

.no-click{
    pointer-events: none;
}

.pBold{
    font-weight: bold;
}

.tCenter{
    text-align: center;
}

.bg-brand{
    background-color: v.$brand_color;
}

.bg-brand--1{
    background-color: v.$brand_color--1;
}

.bg-brand--2{
    background-color: v.$brand_color--2;
}

.bg-brand--3{
    background-color: v.$brand_color--3;
}

.bg-brand--4{
    background-color: v.$brand_color--4;
}

.bg-brand--5{
    background-color: v.$brand_color--5;
}

.no-border{
    border:none;
}

.no-display{
    display: none !important;
}

.hover{
    &:hover{
        cursor: pointer;
    }
}

.overlay{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
    display: grid;
    place-items: center;
    overflow: scroll;
    @include m.tablet{
        place-items: center;
    }
    img{
        width: 55%;
        @include m.desktop{
            width: 40%;
        }
    }
}