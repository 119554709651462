@use "sass:math";
@use 'variables' as v;
@use 'mixins' as m;

html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

body {
    background-color: v.$background_color;
    font-family: v.$font;
    font-size: 1.6rem;
    line-height: 1.6;
    position: relative;
    color: v.$font_color;
}

a {
    text-decoration: none;
    margin: 0;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3, h4{
    margin: 0;
}

h1, h2{
    font-weight: v.$font--bold;
}

h3, h4{
    font-weight: v.$font--regular;
}

h1 {
    font-weight: v.$black;
    font-size: v.$font_size--5;
    @include m.tablet{
        font-size: v.$font_size--6;
    }
    @include m.bigScreen{
        font-size: v.$font_size--7;
    }
    @include m.giant{
        font-size: v.$font_size--9;
    }
}
h2 {
    font-size: v.$font_size--3;
    @include m.tablet{
        font-size: v.$font_size--4;
    }
    @include m.bigScreen{
        font-size: v.$font_size--5;
    }
    @include m.giant{
        font-size: v.$font_size--6;
    }
}
h3 {
    font-size: v.$font_size--2;
    @include m.tablet{
        font-size: v.$font_size--3;
    }
    @include m.bigScreen{
        font-size: v.$font_size--4;
    }
    @include m.giant{
        font-size: v.$font_size--5;
    }
}
h4 {
    font-size: calc(v.$font_size + 0.2rem);
    @include m.tablet{
        font-size: v.$font_size--2;
    }
    @include m.bigScreen{
        font-size: v.$font_size--3;
    }
    @include m.giant{
        font-size: v.$font_size--3;
    }
}

p {
    color: v.$font_color;
    font-size: calc(v.$font_size - 0.2rem);
    @include m.desktop{
        font-size: v.$font_size;
    }
    @include m.bigScreen{
        font-size: v.$font_size--1;
    }
}