/* 
//////////////////////////////////////
    CSS GLOBAL
        *Fonts
        *Screen sizes
        *Font weight
        *Colors
        *Sizes
        *Font colors
        *Font sizes
        *Shadows
        *Borders
        *Buttons
    //////////////////////////////////////
    SECTIONS
        *Header
        *Footer
        *Main
        *Cards
        *Forms
        *Banners
//////////////////////////////////////
*/

/* CSS GLOBAL
//////////////////////////////////////
*/
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600;700;900&display=swap');

// Fonts
$font: 'Montserrat', sans-serif;
$font--alt: 'Orbitron', sans-serif;

//Screen sizes
$mini: 280px;
$telefono: 480px;
$tablet: 850px;
$desktop: 1024px;
$bigScreen: 1250px;
$ultraWide: 1440px;
$giant: 2200px;

// Font weight
$font--thin: 300;
$font--regular: 400;
$font--semibold: 600;
$font--bold: 700;
$font--black: 900;

// Colors
$black: #000000;
$white: #FFFFFF;

$brand_color: #101010;
$brand_color_hover: #59C3E9;
$brand_color_active: #FBD75A;
$brand_color_disabled: #ACACAC;
$brand_color_text: $white;

$brand_color--1: #e7237b;
$brand_color--1_hover: #3ec3f7;
$brand_color--1_active: #3ec3f7;
$brand_color--1_disabled: #ACACAC;
$brand_color--1_text: #FFFFFF;

$brand_color--2: #3ec3f7;
$brand_color--2_hover: #000000;
$brand_color--2_active: #000000;
$brand_color--2_disabled: #ACACAC;
$brand_color--2_text: #FFFFFF;

$brand_color--3: #4CAB27;
$brand_color--3_hover: #1A4715;
$brand_color--3_active: #1A4715;
$brand_color--3_disabled: #ACACAC;
$brand_color--3_text: #FFFFFF;

$brand_color--4: #f9c943;
$brand_color--4_hover: #1A4715;
$brand_color--4_active: #1A4715;
$brand_color--4_disabled: #ACACAC;
$brand_color--4_text: #FFFFFF;

$brand_color--5: #161424;
$brand_color--5_hover: #1A4715;
$brand_color--5_active: #1A4715;
$brand_color--5_disabled: #ACACAC;
$brand_color--5_text: #FFFFFF;

$alert_success: #56f136;
$alert_error: #e83b3b;

$background_color: $brand_color;

//Sizes
$gap: 5rem;
$big--gap: 10rem;
$medium--gap: 3rem;
$small--gap: 1rem;
$base: 0.4rem;

//Font colors
$font_color: $white;
$font_color--1: $brand_color--1;
$font_color--2: $brand_color;
$font_color--3: $black;
$font_color--4: #929292;
$font_disabled: #ACACAC;
$font_input: #D9D9D9;

//Font sizes
$font_size: calc($base * 4); //1.6rem (16px)
$font_size--1: calc($base * 5); //2rem (20px)
$font_size--2: calc($base * 6); //2.4rem (24px)
$font_size--3: calc($base * 7); //2.8rem (28px)
$font_size--4: calc($base * 8); //3.2rem (32px)
$font_size--5: calc($base * 9); //3.6rem (36px)
$font_size--6: calc($base * 10); //4rem (40px)
$font_size--7: calc($base * 11); //4.4rem (44px)
$font_size--8: calc($base * 12); //4.8rem (48px)
$font_size--9: calc($base * 13); //5.2rem (52px)
$font_size--10: calc($base * 14); //5.6rem (56px)

//Shadows
$shadow: 0.7rem 0.7rem 1.4rem rgba(77, 5, 75, 0.5);
$shadow--1: 0.7rem 1rem 0.8rem rgba(172, 161, 161, 0.5);
$shadow--2: 0.8rem 1.1rem 1.2rem rgba(172, 161, 161, 0.5);

//Borders
$border-radius: 0.5rem;
$border-radius--1: 1rem;
$border-radius--2: 1.5rem;

$border-color: #D9D9D9;
$border-color--1: $brand_color;
$border-width: 0.1rem;
$border-width--1: 0.2rem;
$border-width--2: 0.3rem;
$border-style: solid;

//Buttons
$btn_background: $brand_color;
$btn_background_hover: $brand_color_hover;
$btn_background_active: $brand_color_active;
$btn_background_disabled: $brand_color_disabled;
$btn_background_text: $brand_color_text;

$btn_background--1: $brand_color--1;
$btn_background--1_hover: $brand_color--1_hover;
$btn_background--1_active: $brand_color--1_active;
$btn_background--1_disabled: $brand_color--1_disabled;
$btn_background--1_text: $brand_color--1_text;

$btn_background--2: $brand_color--2;
$btn_background--2_hover: $brand_color--2_hover;
$btn_background--2_active: $brand_color--2_active;
$btn_background--2_disabled: $brand_color--2_disabled;
$btn_background--2_text: $brand_color--2_text;

$btn_background--3: $brand_color--3;
$btn_background--3_hover: darken($brand_color--3_hover, 5);
$btn_background--3_active: $brand_color--3_active;
$btn_background--3_disabled: $brand_color--3_disabled;
$btn_background--3_text: $brand_color--3_text;

/* SECTIONS
//////////////////////////////////////
*/

//Header
$header_background: transparent;
$header_background--1: $brand_color--5 ;
$header_height: 70vh;
$header_title: $font_color;
$header_title_weight: $font--bold;

$header_color: $font_color;
$header_color_hover: $brand_color--2;
$header_color_active: $brand_color--2;
$header_color_disabled: $font_disabled;

$header_img_width: 100%;
$header_img_height: 100%;
$header_img_size: cover;
$header_img_position: center center;
$header_img_repeat: no-repeat;

//Footer
$footer_background: $brand_color--2;
$footer_height: auto;

$footer_color: $font_color--1;
$footer_color_hover: $font_color--1;
$footer_color_active: $font_color--1;
$footer_color_disabled: $font_disabled;

$footer_button: $brand_color--1;
$footer_button_hover: $brand_color--1_hover;
$footer_button_active: $brand_color--1_active;
$footer_button_disabled: $brand_color--1_disabled;
$footer_button_text: $brand_color--1_text;

//Main
$main_width: 85%;

$main_title: $font_color;
$main_text--1: $font_color;
$main_title_weight: $font--bold;
$main_title_size: 5rem;

$main_title--1: $font_color--4;
$main_title--1_weight: $font--semibold;
$main_title--1_size: 2.5rem;

$main_button: $brand_color;
$main_button_hover: $brand_color--3;
$main_button_active: $brand_color_active;
$main_button_disabled: $brand_color_disabled;
$main_button_border_radius: $border-radius--2;
$main_button_text: $brand_color_text;
$main_button_icon: $font_color--1;
$main_button_icon_size: 5rem;

//Cards
$card_background: $background_color;
$card_shadow: $shadow;
$card_border_radius: $border-radius--2;

$card_title: $font_color--2;
$card_title_weight: $font--bold;
$card_title_icon: $brand_color;
$card_text: $font_color;
$card_icon_size: 7rem;

//Forms
$form_background: $background_color;
$form_shadow: $shadow--2;

$form_title: $font_color--4;
$form_title_weight: $font--bold;
$form_title_size: 4rem;

$label_text: transparent;
$label_text_weight: $font--bold;
$label_text_size: 1.6rem;

$input_background: $background_color;
$input_border: $border-color;
$input_border_width: $border-width;
$input_border_style: $border-style;
$input_border_radius: $border-radius;
$input_width: 100%;

$input_text: $font_input;
$input_text_weight: $font--thin;
$input_text_size: 1.6rem;

$input_submit: $brand_color--1;
$input_submit_hover: $brand_color--1_hover;
$input_submit_active: $brand_color--1_active;
$input_submit_disabled: $brand_color--1_disabled;
$input_submit_text: $brand_color--1_text;

$input_submit--1: $brand_color--2;
$input_submit--1_hover: $brand_color--2_hover;
$input_submit--1_active: $brand_color--2_active;
$input_submit--1_disabled: $brand_color--2_disabled;
$input_submit--1_text: $brand_color--2_text;

//Banners
$banner_background: $brand_color;
$banner_background_linear: linear-gradient(to right, #54AF33, #0E029A);
$banner_height: 32rem;
$banner_width: 100%;
$banner_title: $font_color--1;
$banner_title_weight: $font--bold;
$banner_title_size: 3.2rem;
$banner_title_size--1: 4rem;
$banner_title_padding: 0 10%;
$banner_text: $font_color--1;
$banner_text_weight: $font--thin;
$banner_text_size: 2rem;
$banner_background--1: $brand_color--1;
$banner_background--radial: radial-gradient(ellipse at bottom, $brand_color--1 0%, darken($brand_color--1, 28%) 15%, $brand_color 45%, $brand_color--5 75%);
