@use "sass:math";
@use 'base/variables' as v;
@use 'base/mixins' as m;

.header{
    background: v.$banner_background--radial;    
    &__overlay{
        background-image: url('../img/banner-background.svg');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        width: 100%;
        height: 100%;
    }
    &__bar{
        display: flex;
        justify-content: space-between;
        padding: v.$medium--gap v.$big--gap ;
    }
    &__logo{
        display: flex;
        justify-content: center;
        align-items: center;
        &__img{
            width: 5.5rem;
        }
        &__title{
            color: v.$header_title;
            font-size: v.$font_size--5;
            font-weight: v.$header_title_weight;
            font-family: v.$font--alt;
            margin-left: v.$small--gap;
        }
    } 
    &__nav{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 65%;
        &__list{
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 100%;
            &__item{
                &__link{
                    color: v.$header_color;
                    font-size: v.$font_size--2;
                    font-weight: v.$font--thin;
                    &:hover{
                        color: v.$header_color_hover;
                    }
                    &:active{
                        color: v.$header_color_active;
                    }
                    &:disabled{
                        color: v.$header_color_disabled;
                    }
                    &--auth{
                        @extend .header__nav__list__item__link;
                        color: v.$brand_color--1_text;
                        &:hover{
                            color: v.$brand_color--1_text;
                        }
                    }
                }
                &:hover{
                    cursor: pointer;
                }
                &--auth{
                    @extend .header__nav__list__item;
                    background-color: v.$brand_color--1;
                    border-radius: v.$border-radius;
                    padding: math.div(v.$small--gap, 2) v.$medium--gap;
                    &:hover{
                        background-color: v.$brand_color--1_hover;
                    }
                }
            }
        }
    }
}