@use 'sass:math';
@use 'base/variables' as v;
@use 'base/mixins' as m;

.banner{    
    &__wrapper{        
        @include m.tablet{
            display: flex;
            flex-direction: row-reverse;
            gap: v.$gap;
            justify-content: center;
            padding: v.$medium--gap;
            padding-bottom: 0;
        }
    }
    &__image{
        max-width: 35vw;
        display: block;
    }
    &__content{
        width: 100%;
        background-color: rgba(0,0,0,0.45);
        border-radius: v.$border-radius--1;
        min-height: v.$gap;
        padding: v.$gap;
        transform: translateY(-3rem);
        display: grid;
        place-items: center;
        &__title{
            line-height: 1.4;
            color: v.$brand_color--4;
            background-image: linear-gradient(to left, v.$brand_color--1, v.$brand_color--4);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-weight: v.$font--black;
            text-shadow: v.$shadow;
            text-align: center;
        }
    }

    &__search{
        position: relative;
        width: 100%;
        &__input{
            width: 100%;
            display: block;
            background-color: transparent;
            border-radius: v.$border-radius--1;
            border: v.$border-width--2 v.$border-style v.$border-color;
            padding: math.div(v.$gap, 2);
            font-size: v.$font_size--1;
            color: v.$font_color;
            &::placeholder{
                color: v.$font_color;
            }
            &:focus{
                outline: none;
            }    
        }
        &__button{
            position: absolute;
            top: 50%;
            right: 2%;
            transform: translateY(-50%);
            background-color: transparent;
            border: none;
            cursor: pointer;
            &:focus{
                outline: none;
            }
        }
        &__icon{
            font-size: v.$font_size--2;
            color: v.$font_color;
        }
    }
}